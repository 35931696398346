import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import GoogleShoppingDroneImg from 'images/blog-images/google-shopping-drones.png';
import GoogleShipping2020Listing from 'images/blog-images/google-shopping-2020.png';
import GoogleShipping2020ListingFeatured from 'images/featured-image/google-shopping-2020-featured.png';
import GoogleShoppingShoesImg from 'images/blog-images/google-shopping-bags.png';


interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query GoogleShoppingImg {
      file(relativePath: { eq: "google-shopping-2020.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="Google Shopping 2020"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Google Shopping 2020: A Guide for Retailers | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-google-shopping-2020" })}
         meta={
          [
            {
              name: `description`,
              content: "Google Shopping 2020 update has so much to offer. Find out on Shipkoo what’s new this 2020. Is it a yay or nay for retailers? ",
            },
            {
              property: `og:title`,
              // content: "Google Shopping 2020: A Guide for Retailers",
              content: intl.formatMessage({ id: "blog-post-meta-title-google-shopping-2020" }),
            },
            {
              property: `og:description`,
              content: "Google Shopping 2020 update has so much to offer. Find out on Shipkoo what’s new this 2020. Is it a yay or nay for retailers? ",
            },
            {
              property: `og:image`,
              content: GoogleShipping2020ListingFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="google-shopping-2020-a-guide-for-retailers"
        title="Google Shopping 2020: A Guide for Retailers"
        date="2020-08-13"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            
            <Image src={GoogleShoppingShoesImg} className="no-top" alt="Bags, shoes and wallets to be listed for free on Google Shopping. "/>

            <p>
              Google Shopping is an excellent way of driving traffic to your store that converts. In 2019, <a href="https://hello.getsidecar.com/2019-benchmarks-report-google-ads-retail" target="_blank" rel="noreferrer">Google Shopping benchmarks report</a> showed that retailers continue to find new opportunities in Google Ads. Their year-over-year revenue growth in Google Shopping increased to 29% and an increase of 13% for paid search.
            </p>

            <p>
              Google Shopping is a titan when it comes to boosting up eCommerce sales with a proven track record. When using Google Shopping, your products show up as a relevant search topic, a huge percentage of those who click to your ads are just moments away from buying your product. 
            </p>

            <p>
              It is also a given fact that most of the users tend to search in Google first before going to any online marketplaces. That’s why Google Shopping can be a huge factor for your eCommerce business.
            </p>

            <h5>
              <strong>
                What is Google Shopping?
              </strong>
            </h5>

            <p>
              Google Shopping is a comparison shopping engine, which allows users to search, compare, and shop products across different retailers who have paid to advertise their products. Their goal is to make product searches relatively easy for the users and connect sellers to customers with ease.
            </p>

            <p>
              If a user searches for a product that you sell, Google will show relevant product listings, including yours and your competitors’. If the user clicks on your listing, Google will redirect the user to your product page.
            </p>

            <h5>
              <strong>
                How Does It Work?
              </strong>
            </h5>

            <p>
              Google Shopping allows users to view product information from these sellers regularly updated daily by the sellers. Online retailers submit their updates containing product information to Google Shopping. Since the product information comes directly from the seller, Google can show the current prices, the latest offers, and ongoing product availability.
            </p>

            <p>
              Users see Shopping Ads that are relevant to their searches, showing an image of the product, together with its price and other information like customer rating. If the user likes the product, they click on your ad, which will take them to your product page. In return, Google will charge you for that click.
            </p>

            <h5>
              <strong>
                What’s New This 2020?
              </strong>
            </h5>

            <p>
              There’s been a lot of going on this year around Google Shopping’s game-changing updates. Google is consistently making tweaks and improvements to the Merchant Center to give retailers better control over their ads.
            </p>

            <Image src={GoogleShoppingDroneImg} className="" alt="Drone search on Google Shopping free listing platform"/>

            <h5>
              <strong>
                Google Shopping switches to FREE LISTINGS
              </strong>
            </h5>

            <p>
              In late April this year, Google announced that search results on the Google Shopping tab would consist primarily of <a href="https:/blog.google/products/shopping/bringing-free-retail-listings-google-search/" target="_blank" rel="noreferrer">free listings</a>, helping more merchants connect with consumers. With online shopping steadily increasing its popularity daily during this crisis, shopper’s demands increase, and retailers cannot keep up with the inflation in these demands.
            </p>

            <p>
              For shoppers, more products to Google Shopping means more options to choose from. For merchants, especially to the new ones, they can have more chances to drive leads and increase online visibility. The chances of customers discovering your products and making a purchase increases, without having to do a paid ad.
            </p>

            <h5>
              <strong>
                Use your preferred shipping carrier
              </strong>
            </h5>

            <p>
              Setting up your shipping settings on Google Shopping is straightforward. You can use any <Link to="/services/express-shipping/">shipping method</Link>, however if the courier API is not supported you may need to add the shipping price manually. When setting it up, you’ll configure a series of steps to get your ads up and ready. If your store provides multiple shipping services for a product in the same country, Google will calculate the lowest shipping rate for the product for a given situation and display it to the shopper.
            </p>

            <p>
              The shipping costs that you set must match the costs that you charge on your store. If you can’t match the cost exactly, overestimating shipping costs can be another option. The same goes for the delivery time, and Google will let you set up your order cut-off time, delivery methods, handling time, and others based on how your online store does its delivery process.
            </p>

            <p>
              Keep in mind that any mismatched information from your Google Shopping Ad and to your online store can be used to decide whether to transact at your store or not.
            </p>

            <Image src={GoogleShipping2020Listing} alt="Shoes, gaming console and shopping bags ready to be for Google Shopping free listing"/>

            <h5>
              <strong>
                PayPal partnership
              </strong>
            </h5>

            <p>
              Google and PayPal have an ongoing partnership since a couple of years ago, which made things possible such as being able to link your account with Google Pay. On top of the free listing announcement, they’ve also announced that Paypal will help bring more merchants onto the platform faster. This can be achieved by letting merchants link their existing accounts for accepting online payments.
            </p>

            <p>
              This new announcement will make the Google Merchant Center account verification process more comfortable than before. When linking your PayPal account to your Merchant Account, it lets Google know that the merchant account is "trusted".
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;